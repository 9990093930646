<template>
  <div class="row">
    <div class="col-12 col-lg-5 c">
        <br>
        <br>
        <div class="card card-body">
            <div class="col-12" v-if="loading">
                <div class="alert alert-warning text-center g">
                    جاري الدخول...
                </div>
            </div>
            <div class="col-12" v-if="done">
                <div class="alert alert-success text-center g">
                    تم بنجاح
                </div>
            </div>
            <div class="form-group" v-if="!done">
              <h4 for="">كود الدخول</h4>
              <input type="text"
                class="form-control text-center form-control-lg" v-model="otp" placeholder="اكتب هنا...">
            </div>
            <div class="col-12 text-center g" v-if="!done">
                <button class="btn btn-primary btn-block" @click="donex()">
                    تسجيل الدخول
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            otp: null,
            done: false,
            loading: false
        }
    },
    methods: {
        donex(){
            var g = this;
            g.loading = true;
            $.post(api + '/user/projects/otp', {
                id: window.location.href.split("id=")[1],
                otp: this.otp
            }).then(function(r){
                g.loading = false;
                if(r.status == 100){
                    g.done = true
                }else{
                    alert(r.response)
                }
            }).fail(function(){
            g.loading = false;
                g.done = false;
                alert('حدث خطأ')
            })
        }
    }
}
</script>

<style>

</style>